html,
body,
#root,
#App {
  width: 100%;
  min-height: 100vh;
}

a {
  color: #275ddb;
  text-decoration: none;
}

a:hover {
  color: #77a0ef;
  text-decoration: underline;
}
